var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": false,
        "nudge-width": "340",
        "nudge-top": "40",
        "nudge-right": "10",
        "offset-x": "",
        "content-class": "v-application",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "a",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "d-block mt-2 cancellation-history",
                      class: { "text-decoration-underline": _vm.isOpen },
                      attrs: { role: "link" },
                      on: { click: _vm.onViewCancellationHistory },
                    },
                    "a",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    "\n            Anzahl der Stornos: " +
                      _vm._s(_vm.cancellation_history_count) +
                      "\n        "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        [
          _c("v-card-title", [_vm._v("Anzahl der Stornos")]),
          _vm._v(" "),
          _c("v-card-text", [
            _c("div", [
              _vm.cancellation_history_count > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "v-list",
                        _vm._l(
                          _vm.cancellationHistory,
                          function (cancellation, index) {
                            return _c(
                              "v-list-item-group",
                              { key: index },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c("v-list-item-title", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex align-center",
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    cancellation.firstname
                                                  ) +
                                                    " " +
                                                    _vm._s(
                                                      cancellation.lastname
                                                    )
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    href: `/pharmacists/${cancellation.pharmacist_id}`,
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            size: "20",
                                                            color: "blue",
                                                          },
                                                        },
                                                        "v-icon",
                                                        _vm.attrs,
                                                        false
                                                      ),
                                                      _vm.on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        "\n                                                    mdi-open-in-new\n                                                "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(
                                            "\n                                        Cancellation Date: " +
                                              _vm._s(
                                                cancellation.date.slice(0, 10)
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(
                                            "\n                                        Selbst zugewiesen: " +
                                              _vm._s(
                                                cancellation.is_self_assigned
                                                  ? "Ja"
                                                  : "Nein"
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("v-list-item-subtitle", [
                                          _vm._v(
                                            "\n                                        Vom FB storniert: " +
                                              _vm._s(
                                                cancellation.is_cancelled_by_pharmacist
                                                  ? "Ja"
                                                  : "Nein"
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                index < _vm.cancellation_history_count - 1
                                  ? _c("v-divider")
                                  : _vm._e(),
                              ],
                              1
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  )
                : _c("div", [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex align-center",
                            attrs: { cols: "auto" },
                          },
                          [
                            _c(
                              "v-icon",
                              { attrs: { large: "", color: "red" } },
                              [_vm._v("mdi-history")]
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { staticClass: "d-flex", attrs: { cols: "auto" } },
                          [
                            _c("p", { staticClass: "display-1" }, [
                              _vm._v("Keine Stornogeschichte verfügbar"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
            ]),
          ]),
          _vm._v(" "),
          _c("v-divider"),
          _vm._v(" "),
          _c(
            "v-card-actions",
            { staticClass: "justify-end" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: { color: "secondary", text: "" },
                  on: {
                    click: function ($event) {
                      _vm.isOpen = false
                    },
                  },
                },
                [_vm._v("\n                Close\n            ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }