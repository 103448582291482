<template>
    <v-menu v-model="isOpen" :close-on-content-click="false" nudge-width="340" nudge-top="40" nudge-right="10" offset-x
        content-class="v-application">
        <template v-slot:activator="{ on, attrs }">
            <a v-bind="attrs" v-on="on" class="d-block mt-2 cancellation-history"
                :class="{ 'text-decoration-underline': isOpen }" role="link" @click="onViewCancellationHistory">
                Anzahl der Stornos: {{ cancellation_history_count }}
            </a>
        </template>

        <v-card>
            <v-card-title>Anzahl der Stornos</v-card-title>
            <v-card-text>
                <div>
                    <div v-if="cancellation_history_count > 0">
                        <v-list>
                            <v-list-item-group v-for="(cancellation, index) in cancellationHistory" :key="index">
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <div class="d-flex align-center">
                                                <span>{{ cancellation.firstname }} {{ cancellation.lastname }}</span>
                                                <a :href="`/pharmacists/${cancellation.pharmacist_id}`" target="_blank"
                                                    class="ml-3">
                                                    <v-icon size="20" color="blue" v-bind="attrs" v-on="on">
                                                        mdi-open-in-new
                                                    </v-icon>
                                                </a>
                                            </div>
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                            Cancellation Date: {{ cancellation.date.slice(0, 10) }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            Selbst zugewiesen: {{ cancellation.is_self_assigned ? 'Ja' : 'Nein' }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle>
                                            Vom FB storniert: {{ cancellation.is_cancelled_by_pharmacist ? 'Ja' : 'Nein' }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-divider v-if="index<cancellation_history_count-1"></v-divider>
                            </v-list-item-group>
                        </v-list>
                    </div>
                    <div v-else>
                        <div class="d-flex align-center">
                            <v-col class="d-flex align-center" cols="auto">
                                <v-icon large color="red">mdi-history</v-icon>
                            </v-col>
                            <v-col class="d-flex" cols="auto">
                                <p class="display-1">Keine Stornogeschichte verfügbar</p>
                            </v-col>
                        </div>
                    </div>
                </div>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="justify-end">
                <v-btn @click="isOpen = false" color="secondary" class="mr-2" text>
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-menu>
</template>

<script>
import { mapMutations } from 'vuex'
import axios from 'axios';

export default {
    name: 'CancellationHistoryDialog',
    props: {
        appointment_id: String,
        cancellation_history_count: Number
    },
    data() {
        return {
            isOpen: false,
            cancellationHistory: [],
        }
    },
    methods: {
        async onViewCancellationHistory() {
            try {
                var response = await axios.get(`/api/appointments/${this.appointment_id}/cancellation_history`);
                if (response.status == 200 && response.data) {
                    this.cancellationHistory = response.data;
                }
                else {
                    this.cancellationHistory = [];
                }
            } catch (error) {
                this.alertError()
            }
            this.isOpen = true;
        },
        ...mapMutations({
            alertError: 'alert/error',
        })
    },
}
</script>

<style scoped>
.cancellation-history {
    width: max-content
}
</style>